import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"
import styled from "styled-components"

import { generatePath } from "./../utils/helpers"

const StyledNavigation = styled.ul``

const MenuItem = styled.li`
  display: inline-block;
  vertical-align: middle;

  margin-bottom: 3rem;

  ${props => props.theme.above.desktop`
        margin-bottom: 0;
        margin-left: 3rem;

        &.contact {
            .link {
                padding: 0.8rem 2rem;
                
                background-color: ${props => props.theme.colors.white};
                border-radius: 2rem;

                color: ${props => props.theme.colors.greyDark};
            }
        }

        &:not(.contact) {
            .link {
                ${props => props.theme.underline("currentColor", 0.1)}
            }
        }
    `}
`

const StyledLink = styled(Link)`
  font-family: ${props => props.theme.fontFamily.proximaRegular};
  font-size: 2rem;
  line-height: 2.5rem;

  color: ${props => props.theme.colors.greyDark};

  transition: color 0.35s ${props => props.theme.transitions.cubic};

  &.active,
  &:hover {
    color: rgba(255, 255, 255, 1);
  }

  ${props => props.theme.above.desktop`
        font-size: 1.6rem;
        line-height: 2.5rem;

        color: rgba(255, 255, 255, 0.65);

        &.active, &:hover {
            color: rgba(255, 255, 255, 1);
        }
    `}
`

const Navigation = ({ lang, contentTheme, toggleMenuOpen, className }) => {
  return (
    <StyledNavigation className={className}>
      {contentTheme.navigation.map((item, index) => {
        return (
          <MenuItem key={index} className={item.slug}>
            <StyledLink
              className="link"
              activeClassName="active"
              onClick={toggleMenuOpen && toggleMenuOpen}
              to={generatePath(lang, item.slug)}
            >
              {item.title}
            </StyledLink>
          </MenuItem>
        )
      })}
    </StyledNavigation>
  )
}

export default Navigation
